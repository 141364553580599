import React from "react";
import { Box, Container } from "./styles";
import { Grid } from "@material-ui/core";

import renovaBio from "./../../../../assets/renovaBioLogo.jpeg";

export default function RenovaBioHome(...props) {
    return (
        <Container>
            <div>
                <img src={renovaBio} alt="um" className="imagemInicio" />
            </div>
            <div className="d-flex justify-content-center">
                <Grid container spacing={2} style={{ marginTop: "5vh" }}>
                    <div>
                        <Box style={{ marginTop: 20, borderLeftWidth: "0px", borderBottomWidth: "0px" }} className="d-flex justify-content-center">
                            <p style={{ width: "90%", paddingLeft: "0px", textAlign: "justify", color: "#303484" }}>
                                O RenovaBio é concedida pela Agência Nacional de Petróleo,
                                Gás Natural e Biocombustível (ANP), e certificação permite
                                a emissão de créditos de descarbonização (CBIOs) com base
                                na produção de biodiesel.
                            </p>
                        </Box>
                    </div>
                </Grid>
            </div>
        </Container>
    );
}
