import React from "react";
import { Container, Title } from "./styles";

export default function RatingHome(props) {
    return (
        <Container>
            <Title>
                <p>Rating</p>
                <hr />
            </Title><br />
            <section class="" id="section-tours">

                <h2 class="heading-secondary">
                    <a href="/rating_cereal_elevado_para_brAA.pdf"
                        className="link">
                        Rating Cereal - pdf
                    </a>
                </h2>
            </section>
        </Container>
    );
}