import React from "react";
import { Container, Title } from "./styles";

export default function ApresentacaoInstitucionalHome(props) {
    return (
        <Container>
            <Title>
                <p>Apresentação institucional</p>
                <hr />
            </Title><br />
            <section class="" id="section-tours">

                <h2 class="heading-secondary">
                    <a href="/apresentacao_institucional.pdf"
                        className="link">
                        Apresentação - pdf
                    </a>
                </h2>
            </section>
        </Container>
    );
}