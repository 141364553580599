import React from "react";
import { Box, Container, Title } from "./styles";
import { Grid } from "@material-ui/core";

export default function BPFHome(props) {
    return (
        <Container>
            <Title>
                <p>Certificado BPF</p>
                <hr />
            </Title>
            <br />
            <div className="d-flex justify-content-center">
                <Grid container spacing={2} style={{ marginTop: "5vh" }}>
                    <div>
                        <Box style={{ marginTop: 20, borderLeftWidth: "0px", borderBottomWidth: "0px" }} className="d-flex justify-content-center">
                            <p style={{ width: "90%", paddingLeft: "0px", textAlign: "justify", color: "#303484" }}>
                                O Grupo Cereal possui o Registro de Estabelecimento do Ministério da Agricultura, Pecuária e Abastecimento (MAPA), que
                                autoriza a produção, armazenagem e comercialização de produtos agropecuários no Brasil. Esse registro assegura o
                                cumprimento de normas sanitárias e de qualidade, promovendo a segurança dos alimentos e produtos de origem vegetal,
                                além de garantir a regularidade das operações e possibilitar a comercialização em âmbito nacional
                            </p>
                        </Box>
                    </div>
                </Grid>               
            </div>
            <br/>
            <h2 className="heading-secondary">
                <a href="/certificado_bpf.pdf" className="link">
                    Certificado - pdf
                </a>
            </h2>
        </Container>
    );
}
