import styled from "styled-components";
import Carousel from "react-bootstrap/Carousel";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    font-family: "Roboto";
    color: #fff;
    width: 100%;
`;

export const ContentSlide = styled.div`
    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.3);
    }
`;

export const Section = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 80vh;
`;

export const ContentSobre = styled.div`
    display: flex;
    flex-direction: row;
    z-index: 999;
    padding: 5em;
    padding-bottom: 0px;
    justify-content: space-between;
    background: rgba(255, 255, 255, 1);
`;

export const ContentInstitucional = styled.div`
    div {
        button {
            text-decoration: none;
            transition: 0.2s;
            color: rgba(255, 255, 255, 1);
            background: transparent;
            border: none;

            svg {
                width: 120px;
                transition: 0.5s;

                &:hover {
                    width: 135px;
                    transition: 0.5s;
                }
            }

            &:hover {
                color: rgba(250, 165, 26, 0.9);
                transition: 0.2s;
            }
        }
    }

    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        min-height: 45vh;
        height: 100%;
        background: rgba(0, 0, 0, 0.9);
        @media (max-width: 400px) {
            min-height: 65vh;
        }
    }
`;

export const ContentCertificacao = styled.div`
    background: #ededed;
    display: flex;
    flex-direction: row;
    z-index: 999;
    padding: 3em 5em;
    justify-content: space-between;

    @media (max-width: 400px) {
        flex-direction: column;
    }
`;

export const ContentMissaoEValores = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 999;
    justify-content: space-between;
    text-align: center;
`;

export const BoxMissaoEValores = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 40px;

    @media (max-width: 400px) {
        flex-direction: column;

        div {
            margin-bottom: 10px;
        }
    }
`;

export const ImageSlide = styled.img`
    width: 100%;
    height: 100vh;

    @media (max-width: 400px) {
        height: 73vh;
    }
`;

export const ImageParallax = styled.div`
    width: 100%;
    min-height: 45vh;
    height: 100%;
    background: url(${(props) => props.src});
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 400px) {
        min-height: 65vh;
    }
`;

export const Sections = styled.div`
    display: flex;
    height: 600px;
    margin-top: 100px;
`;

export const SectionItem = styled.div`
    width: 33%;
`;

export const SectionHeader = styled.div`
    width: 100%;
    height: 240px;
    position: relative;
    overflow: hidden;
    background-size: cover !important;
    background: url(${(props) => props.src}) center center no-repeat;

    b {
        bottom: 23px;
        color: #fff;
        font-family: "Roboto";
        font-size: 50px;
        font-weight: bold;
        line-height: 50px;
        padding-left: 30px;
        position: absolute;
        text-transform: uppercase;
        width: 100%;
    }
`;

export const SectionBody = styled.div`
    font-family: "Roboto";
    font-size: 20px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.7);
    border-bottom: 1px solid #eee;
    border-left: 1px solid #eee;

    &:last-child {
        border-right: 1px solid #eee;
    }

    p {
        padding: 30px;
    }
`;

export const ButtonMore = styled.a`
    background: #fff;
    width: 157px;
    height: 50px;
    border-radius: 50px;
    padding: 14px 28px;
    text-align: center;
    color: rgba(0, 0, 0, 0.8);
    font-family: "Martel Sans";
    font-weight: 900;
    font-size: 18px;
    margin-top: 33px;
    display: flex;
    transition: 0.8s;

    @media (max-width: 400px) {
        width: 129px;
        height: 42px;
        font-size: 13px;
    }

    &:hover {
        background: #1f2183;
        color: rgba(255, 255, 255, 0.8);
        transition: 0.8s;
    }
`;

export const Title = styled.div`
    text-align: center;
    color: #1f2183;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        font-size: 20px;
    }

    hr {
        width: 90px;
        height: 3px;
        background: #ff8000;
        right: 150px;
        border: none;
        margin: 0%;
    }
`;
export const Subtitle = styled.h5`
    font-size: 16px;
    font-weight: 400;
    font-family: "Roboto";
    color: rgba(250, 165, 26, 0.9);

    @media (max-width: 400px) {
        font-size: 14px;
    }
`;

export const Text = styled.p`
    font-size: 25px;
    font-family: "Roboto";
    color: rgba(0, 0, 0, 0.8);

    @media (max-width: 400px) {
        font-size: 14px;
    }
`;

export const TextLinhaDoTempo = styled.p`
    font-size: 18px;
    font-family: "Roboto";
    color: #808080;

    @media (max-width: 400px) {
        font-size: 14px;
    }
`;

export const LinhaDoTempoHeader = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    padding: 30px 0px;

    @media (max-width: 400px) {
        flex-direction: column;
    }
`;
export const LinhaDoTempoHeaderItem = styled.button`
    background: ${(src) => (src.active ? "#1F2183" : "transparent")};
    padding: 15px;
    color: ${(src) => (src.active ? "#FFF" : "#1F2183")};
    font-family: "Roboto";
    font-size: 16px;
    margin-right: 30px;
    font-weight: 900;
    border: 2px solid #1f2183;
    height: 60px;
    width: 100px;
    position: relative;
    box-sizing: border-box;
    text-align: center;
    transition: 0.5s;

    @media (max-width: 400px) {
        margin-top: 30px;
        width: 100%;
    }

    &:hover {
        background: #1f2183;
        color: #fff;
        transition: 0.5s;
    }

    &:last-child:after {
        width: 0px;
    }

    &:after {
        content: " ";
        display: block;
        position: absolute;
        height: 1px;
        background: #1f2183;
        width: 30px;
        left: 102%;
        top: calc(50% - 2px);

        @media (max-width: 400px) {
            height: 30px;
            width: 1px;
            left: 48%;
            bottom: -32px;
            top: 104%;
        }
    }
`;
export const LinhaDoTempoBody = styled.div`
    margin-top: 30px;
`;
export const LinhaDoTempoItem = styled.div`
    display: ${(src) => (src.active ? "flex" : "none")};
    flex-direction: row;

    @media (max-width: 400px) {
        flex-direction: column;
    }

    img {
        margin-right: 20px;
        height: 300px;
        @media (max-width: 400px) {
            height: 150px;
            margin-bottom: 20px;
        }
    }
`;

export const PortifolioHeader = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    padding: 30px 0px;
    justify-content: center;
    align-items: center;

    @media (max-width: 1200px) {
        flex-direction: column;
    }
`;
export const PortifolioHeaderItem = styled.button`
    background: ${(src) => (src.active ? "#1F2183" : "transparent")};
    padding: 15px;
    color: ${(src) => (src.active ? "#FFF" : "#1F2183")};
    font-family: "Roboto";
    font-size: 12px;
    margin-right: 30px;
    font-weight: 900;
    border: 2px solid #1f2183;
    height: 60px;
    position: relative;
    box-sizing: border-box;
    text-align: center;
    transition: 0.5s;

    @media (max-width: 1200px) {
        margin: 10px 0px 0px 0px;
        font-size: 12px;
        width: 40vw;
    }

    &:hover {
        background: #1f2183;
        color: #fff;
        transition: 0.5s;
    }
`;

export const TitleSlide = styled.h3`
    color: #fff;
    font-family: "Roboto";
    font-weight: 600;
    font-size: 4em;

    @media (max-width: 400px) {
        font-size: 25px;
    }
`;

export const SubtitleSlide = styled.p`
    color: #fff;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 22px;
    @media (max-width: 400px) {
        font-size: 14px;
    }
`;

export const CaptionSlide = styled(Carousel.Caption)`
    top: 240px;
    width: 600px;
    text-align: left;

    @media (max-width: 400px) {
        width: 75%;
    }
`;
