import React from "react";
import { Box, Card, Container, SectionCard, Title, TitleCard } from "./styles";
import { FaSketch, FaCodepen, FaShirtsinbulk } from "react-icons/fa";

export default function Sobre(...props) {
    return (
        <Container id="sobre">
            <Title>
                <p>Há mais de 40 anos transformando grãos em desenvolvimento e sustentabilidade!</p>
                <p style={{ fontSize: 16 }}>Somos Daqui, Investimos Aqui</p>
                <hr></hr>
            </Title>
            <div class="embed-responsive embed-responsive-21by9">
                <iframe title="Video institucional 2023" class="embed-responsive-item" src="https://www.youtube.com/embed/QEg17nPphZ8"></iframe>
            </div>
            <Title style={{ marginTop: "10vh" }}>
                <p>Sobre</p>
                <hr></hr>
            </Title>

            <Box style={{ marginTop: 20 }}>
                <p>
                    O Grupo Cereal nasceu em 1981, quando seu sócio fundador Evaristo Lira Barauna enxergou a oportunidade de produzir em solo
                    rio-verdense, alimentos e energia para o mundo. Estamos entre as maiores agroindústrias do país e, há mais de 40 anos, oferecemos
                    suporte ao produtor rural em todas as fases da cadeia produtiva. Fornecemos insumos agrícolas (barter) para o plantio, contamos
                    com 10 unidades armazenadoras localizadas no Sudoeste Goiano, além de transportadora própria para garantir agilidade no processo
                    de originação dos grãos e entrega de produtos.
                </p>
                <br></br>
                <p>
                    Nosso complexo industrial é composto por fábricas com tecnologia de ponta para esmagamento de soja (produção de farelo e óleo
                    degomado) e nutrição animal (rações, proteinados e sais minerais). Atuamos também no ramo de exportação (trade) e produção de
                    Biodiesel.
                </p>
                <br></br>
                <p>
                    Presente nos estados de Goiás, Tocantins, Mato Grosso, Mato Grosso do Sul, Minas Gerais e São Paulo, a matriz do Grupo é situada
                    em Rio Verde, cidade considerada a capital do agronegócio do estado de Goiás.
                </p>
                <br></br>
                <p>
                    A empresa é certificada pelo MAPA (Ministério de Agricultura, Pecuária e Abastecimento), por meio de várias instruções normativas.
                    O Grupo Cereal é auditado pelo sistema GMP+ que confere certificação ao Farelo de Soja com padrão elevado de qualidade e dedicado
                    a negociações internacionais.
                </p>
                <br></br>
                <p>
                    O Grupo Cereal se prepara para o futuro, com ações de responsabilidade socioambiental. Além disso, utiliza como modelo de gestão,
                    a Governança Corporativa, formada por um conselho de administração que integra profissionais experientes de mercado e agrega
                    conhecimento e formação aos executivos da empresa.
                </p>
                <br></br>
                <p>Para mais informações, acesse os demais menus do site.</p>
            </Box>

            <SectionCard style={{ margin: "15vh 0px 10vh 0px" }}>
                <Card>
                    <TitleCard>
                        {" "}
                        <FaSketch
                            style={{
                                marginRight: 10,
                                fontSize: 25,
                            }}
                        />{" "}
                        Missão
                    </TitleCard>
                    <hr></hr>
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        <p style={{ fontSize: "14px" }}>Alimentos e Energia para o mundo.</p>
                    </div>
                </Card>
                <Card>
                    <TitleCard>
                        <FaShirtsinbulk
                            style={{
                                marginRight: 10,
                                fontSize: 25,
                            }}
                        />{" "}
                        Valores
                    </TitleCard>
                    <hr></hr>
                    <div>
                        <p style={{ marginLeft: "15px", fontSize: "13px" }}>
                            <span style={{ marginBottom: 15 }}>¬ </span>Ética nos negócios; <br></br>
                            <span style={{ marginBottom: 15 }}>¬ </span>Atitude de dono; <br></br>
                            <span style={{ marginBottom: 15 }}>¬ </span>
                            Criatividade; <br></br>
                            <span style={{ marginBottom: 15 }}>¬ </span>Visão de futuro; <br></br>
                            <span style={{ marginBottom: 15 }}>¬ </span>
                            Competitividade. <br></br>
                        </p>
                    </div>
                </Card>
                <Card>
                    <TitleCard>
                        <FaCodepen
                            style={{
                                marginRight: 10,
                                fontSize: 25,
                            }}
                        />{" "}
                        Visão
                    </TitleCard>
                    <hr></hr>
                    <div>
                        <p
                            style={{
                                textAlign: "center",
                                textJustify: "inter-character",
                                fontSize: "14px",
                            }}
                        >
                            Estar entre as maiores agroindústrias do <br></br>
                            país, crescendo com sustentabilidade, <br></br>
                            gerando riquezas para nossos colaboradores, sociedade, clientes e acionistas.
                        </p>
                    </div>
                </Card>
            </SectionCard>
        </Container>
    );
}
