import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    bottom: 0;
    width: 100%;
    background-color: #191d20;
    color: white;
    text-align: center;
    overflow: hidden;
`;

export const Content = styled.div`
    height: 0px;
`;

export const ContentIcons = styled.div`
    padding-top: 0px;
    padding-bottom: 0px;

    a {
        color: inherit;
    }
`;

export const FooterBottom = styled.div`
    left: 0;
    bottom: 0;
    width: 100%;
    height: 25px;
    padding-bottom: 0px;
    background-color: #0f1215;
    color: white;
    font-family: "Roboto";
    font-weight: 300;
    text-align: center;
    overflow: hidden;
`;

export const FooterBottomIcons = styled.div`
    left: 0;
    bottom: 0;
    width: 100%;
    height: 30px;
    padding-bottom: 0px;
    background-color: #191d20;
    color: white;
    font-family: "Roboto";
    font-weight: 300;
    text-align: center;
    overflow: hidden;
`;