import { createStore, applyMiddleware } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import rootReducers from "./redux/reducers";
import storage from "redux-persist/lib/storage";

const middleware = [thunk];

const persistConfig = {
    key: "root",
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

const store = createStore(persistedReducer, applyMiddleware(...middleware));
const persistor = persistStore(store);

// eslint-disable-next-line import/no-anonymous-default-export
const configureStore = () => {
    return { store, persistor };
};

export default configureStore;
