import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import { DropdownContainer, CloseIcon, DropdownMenu, DivIcon } from "./styles";
import { useLocation } from "react-router";
import LgpdModal from "components/LgpdModal";

const Dropdown = ({ isOpen, toggle }) => {

    const [showCertificadosButtons, setShowCertificadosButtons] = useState(false);
    const [showRelacionamentoInvestidorButtons, setRelacionamentoInvestidorButtons] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const handleCertificadosClick = (e) => {
        e.stopPropagation();
        setShowCertificadosButtons(!showCertificadosButtons);
    };

    const handleRelacionamentoInvestidorsClick = (e) => {
        e.stopPropagation();
        setRelacionamentoInvestidorButtons(!showRelacionamentoInvestidorButtons);
    };

    const handleModalLgpdonContinuar = (checkedAceite) => {
        if (checkedAceite) {
            window.location.href = linkForms;
            setShowModal(false);
        }
    };

    const location = useLocation();
    const linkForms =
        "https://forms.gle/Kwitk7Kx72Xmx9of6";

    const handleLogoClick = () => {
        if (location.pathname === "/lgpd" || location.pathname === "/lgpd-aviso-privacidade" || location.pathname === "/sgi"
            || location.pathname === "/renovabio" || location.pathname === "/bpf" || location.pathname === "/gmp" || location.pathname === "/balancoAuditados" || location.pathname === "/apresentacaoInstitucional"
            || location.pathname === "/relatorioDeTransparencia" || location.pathname === "/rating") {
            window.location.href = "/#home";
        }
    };

    const showAllButtons = location.pathname !== "/lgpd" && location.pathname !== "/lgpd-aviso-privacidade" && location.pathname !== "/sgi"
        && location.pathname !== "/renovabio" && location.pathname !== "/bpf" && location.pathname !== "/gmp" && location.pathname !== "/balancoAuditados" && location.pathname !== "/apresentacaoInstitucional"
        && location.pathname !== "/relatorioDeTransparencia" && location.pathname !== "/rating"
    const showLGPDButton = location.pathname === "/lgpd-aviso-privacidade";

    return (
        <>
        <DropdownContainer isOpen={isOpen} onClick={toggle}>
            
            <DivIcon onClick={toggle} style={{ textAlign: "right" }}>
                <CloseIcon />
            </DivIcon>
            <DropdownMenu>
                {showAllButtons ? (
                    <>
                        <HashLink activeClassName="nav-item-active" to="#home">
                            <li className="item-active">Início</li>
                        </HashLink>
                        <HashLink activeClassName="nav-item-active" to="#sobre">
                            <li className="item-active">Sobre</li>
                        </HashLink>
                        <HashLink activeClassName="nav-item-active" to="#portifolio">
                            <li className="item-active">Portfólio</li>
                        </HashLink>
                        <HashLink activeClassName="nav-item-active" to="#contato">
                            <li className="item-active">Contato</li>
                        </HashLink>
                        <HashLink activeClassName="nav-item-active" to="/lgpd">
                            <li className="item-active">LGPD</li>
                        </HashLink>
                        <a onClick={handleCertificadosClick}>
                            <li className="item-active">Certificados</li>
                            {showCertificadosButtons && (
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <HashLink to="/sgi" activeClassName="nav-item-active">
                                        <li className="item-active">Política SGI</li>
                                    </HashLink>
                                    <HashLink to="/renovabio" activeClassName="nav-item-active">
                                        <li className="item-active">Renovabio</li>
                                    </HashLink>
                                    <HashLink to="/gmp" activeClassName="nav-item-active">
                                        <li className="item-active">GMP</li>
                                    </HashLink>
                                    <HashLink to="/bpf" activeClassName="nav-item-active">
                                        <li className="item-active">BPF</li>
                                    </HashLink>
                                </div>
                            )}
                        </a>
                        <a onClick={handleRelacionamentoInvestidorsClick}>
                            <li className="item-active">Relacionamento com o Investidor</li>
                            {showRelacionamentoInvestidorButtons && (
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <HashLink to="/apresentacaoInstitucional" activeClassName="nav-item-active">
                                        <li className="item-active">Apresentação Institucional</li>
                                    </HashLink>
                                    <HashLink to="/balancoAuditados" activeClassName="nav-item-active">
                                        <li className="item-active">Balanços Auditados</li>
                                    </HashLink>
                                    <HashLink to="/relatorioDeTransparencia" activeClassName="nav-item-active">
                                        <li className="item-active">Relatório de Transparência</li>
                                    </HashLink>
                                    <HashLink to="/rating" activeClassName="nav-item-active">
                                        <li className="item-active">Rating</li>
                                    </HashLink>
                                </div>
                            )}
                        </a>
                        <text activeClassName="nav-item-active" href={linkForms} onClick={() => setShowModal(true)}>
                            <li className="item-active" style={{ backgroundColor: "#30348c", color: "white" }}>
                                Trabalhe Conosco
                            </li>
                        </text>
                    </>
                ) : showLGPD(showLGPDButton, handleLogoClick)}
            </DropdownMenu>
            <LgpdModal showModal={showModal} onHideModal={()=>setShowModal(false)} onContinuarModal={handleModalLgpdonContinuar}/>

        </DropdownContainer>
       
                    <LgpdModal showModal={showModal} onHideModal={()=>setShowModal(false)} onContinuarModal={handleModalLgpdonContinuar}/>

        </>
    );
};

export default Dropdown;

function showLGPD(showLGPDButton, handleLogoClick) {
    if (showLGPDButton) {
        return (
            <>
                <HashLink to="#home" activeClassName="nav-item-active" onClick={handleLogoClick}>
                    <li className="item-active">Início</li>
                </HashLink>
                <HashLink to="/lgpd" activeClassName="nav-item-active">
                    <li className="item-active">LGPD</li>
                </HashLink>
            </>
        );
    } else {
        return (
            <>
                <HashLink to="#home" activeClassName="nav-item-active" onClick={handleLogoClick}>
                    <li className="item-active">Início</li>
                </HashLink>
            </>
        );
    }
}
