import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    USER_FOUND,
    LOGOUT,
    SET_TOKEN,
} from "../actions/types";

const initialState = {
    user: null,
    isLoggedIn: false,
    token: null,
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    if (type === REGISTER_SUCCESS || REGISTER_FAIL || LOGIN_FAIL) {
        return {
            ...state,
            isLoggedIn: false,
        };
    } else if (type === LOGIN_SUCCESS) {
        return {
            ...state,
            isLoggedIn: true,
        }
    } else if (type === LOGOUT) {
        return {
            ...state,
            isLoggedIn: false,
            user: null,
        };
    } else if (type === USER_FOUND) {
        return {
            ...state,
            isLoggedIn: true,
            user: payload,
        };
    } else if (type === SET_TOKEN) {
        return {
            ...state,
            isLoggedIn: true,
            token: payload.token,
        };
    } else {
        return state;
    }
}
