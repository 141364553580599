import React from "react";
import "./Footer.css";
import { ContentIcons, FooterBottom, FooterBottomIcons } from "./styles";
import { FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa";
import { Container } from "react-bootstrap";

function Footer() {
    return (
        <div className="main-footer">
            <Container>
                <FooterBottom>
                    <p style={{ fontWeight: 300, fontSize: 14 }}>
                        © COPYRIGHT© 2021 GRUPO CEREAL INOVA. TODOS OS DIREITOS
                        RESERVADOS.
                    </p>
                </FooterBottom>
            </Container>
            <Container>
                <FooterBottomIcons>
                    <ContentIcons>
                        <a
                            href="https://www.facebook.com/facegrupocereal/?ref=br_rs"
                            target="_new"
                        >
                            <FaFacebookF style={{ fontSize: 24, marginRight: 30 }} />
                        </a>
                        <a
                            href="https://www.linkedin.com/company/grupo-cereal-s-a/"
                            target="_new"
                        >
                            <FaLinkedin style={{ fontSize: 28, marginRight: 30 }} />
                        </a>

                        <a
                            href="https://www.instagram.com/grupocereal/?hl=pt-br"
                            target="_new"
                        >
                            <FaInstagram style={{ fontSize: 28, marginRight: 30 }} />
                        </a>
                    </ContentIcons>
                </FooterBottomIcons>
            </Container>
        </div>
    );
}

export default Footer;
