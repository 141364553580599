import React from "react";
import { Container, Title } from "./styles";
import "./style.css";

export default function BalancosAuditadosHome(props) {
    return (
        <Container>
            <Title>
                <p>Balanços Auditados</p>
                <hr />
            </Title>
            <section class="" id="section-tours">
                <h2 class="heading-secondary">
                    <a href="Balanço_grupo_cereal_2024.pdf" className="link">
                        2024
                    </a>
                </h2>
                <h2 class="heading-secondary">
                    <a href="Balanço_grupo_cereal_2023.pdf" className="link">
                        2023
                    </a>
                </h2>
                <h2 class="heading-secondary">
                    <a href="Balanço_grupo_cereal_2022.pdf" className="link">
                        2022
                    </a>
                </h2>
                <h2 class="heading-secondary">
                    <a href="Balanço_grupo_cereal_2021.pdf" className="link">
                        2021
                    </a>
                </h2>

                <h2 class="heading-secondary">
                    <a href="Balanço_grupo_cereal_2020.pdf" className="link">
                        2020
                    </a>
                </h2>
            </section>
        </Container>
    );
}
