import React from "react";
import { Box, Container, Title } from "./styles";

import tratamentoDados from "../../../assets/aviso-privacidade/tratamento-dados.png";
import lgpd from "../../../assets/aviso-privacidade/LGPD.png";
import { Grid } from "@material-ui/core";

export default function LgpdAvisoPrivacidadeHome(...props) {
    return (
        <Container style={{marginTop: "109px"}}>
            <div>
                <Title >
                    <p>Aviso de Privacidade</p>
                    <hr></hr>
                </Title>

                <Box style={{ marginTop: 20 }}>
                    <div className="d-flex justify-content-center">
                        <p style={{ width: "70%", textAlign: "justify" }}>
                            O Grupo Cereal visando a segurança e proteção dos dados pessoais de seus clientes e colaboradores, busca estar sempre
                            implementando técnicas de segurança da informação e gerindo seus dados pessoais como titular da maneira mais transparente
                            possível, diante disso veja abaixo o que é a Lei Geral de Proteção de Dados e como você se beneficia dela.
                        </p>
                        <br></br>
                    </div>
                </Box>
            </div>

            <div style={{ backgroundColor: "#e4f5fc" }} className="d-flex justify-content-center">
                <Grid container spacing={2} style={{ marginTop: "5vh", marginBottom: "5vh" }}>
                    <Grid item xs={4}>
                        <div className="d-flex justify-content-center">
                            <div className="d-flex justify-content-end">
                                <img style={{ width: "70%", height: "auto", marginLeft: "0px" }} src={lgpd} alt="Descrição da imagem" />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={8}>
                        <div>
                            <Title>
                                <p>Lei Geral de Proteção de Dados Pessoais </p>
                                <hr></hr> <br></br>
                            </Title>
                            <Box
                                style={{ marginTop: 20, borderLeftWidth: "0px", borderBottomWidth: "0px" }}
                                className="d-flex justify-content-center"
                            >
                                <p style={{ width: "90%", paddingLeft: "0px", textAlign: "justify" }}>
                                    Essa é a lei n. 13.709 / 18 onde através dela é listado 54 artigos que são direcionados tanto para a empresa
                                    (CONTROLADOR), o usuário (TITULAR DO DADO) e demais atores como operador e o DPO. Nela encontramos listados todos
                                    os direitos e deveres do titular do dado que no caso é você, sempre demonstrando as orientações para criar e gerir
                                    um ambiente seguro a seus dados pessoais.
                                </p>
                            </Box>
                        </div>
                    </Grid>
                </Grid>
            </div>

            <div className="d-flex justify-content-center">
                <Grid container spacing={2} style={{ marginTop: "5vh", marginBottom: "5vh" }}>
                    <Grid item xs={4}>
                        <div className="d-flex justify-content-center">
                            <div className="d-flex justify-content-end">
                                <img style={{ width: "70%", height: "auto", marginLeft: "0px" }} src={tratamentoDados} alt="Descrição da imagem" />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={8}>
                        <div>
                            <Title>
                                <p>O que caracteriza o tratamento de dados? </p>
                                <hr></hr> <br></br>
                            </Title>
                            <Box
                                style={{ marginTop: 20, borderLeftWidth: "0px", borderBottomWidth: "0px" }}
                                className="d-flex justify-content-center"
                            >
                                <p style={{ width: "90%", paddingLeft: "0px", textAlign: "justify" }}>
                                    Na LGPD mais especificamente no artigo 5º inciso X, tratamento de dados pessoais consiste em ‘toda operação
                                    realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização,
                                    acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação
                                    ou controle da informação, modificação, comunicação, transferência, difusão ou extração;’. Dessa forma cabe ao
                                    CONTROLADOR garantir a eficiência na proteção do dado pessoal em todas essas operações.
                                </p>
                            </Box>
                        </div>
                    </Grid>
                </Grid>
            </div>

            <div style={{ backgroundColor: "#e4f5fc" }}>
                <Title style={{ marginTop: "10vh" }}>
                    <p>Quais os direitos do titular? </p>
                    <hr></hr>
                </Title>

                <Box style={{ marginTop: 20 }}>
                    <div className="d-flex justify-content-center">
                        <p style={{ width: "70%", textAlign: "justify" }}>
                            Caso queira é seu direito solicitar ao CONTROLADOR a obtenção da relação dos dados do titular que são por ele tratados,
                            isso a qualquer momento mediante requisição, segue abaixo os direitos listados em lei do titular do dado.
                            <br></br>
                            <br></br>
                            <p style={{ marginLeft: "15px" }}>
                                <span style={{ marginBottom: 15 }}>¬ </span>I - confirmação da existência de tratamento; <br></br>
                                <span style={{ marginBottom: 15 }}>¬ </span>II - acesso aos dados; <br></br>
                                <span style={{ marginBottom: 15 }}>¬ </span>
                                III - correção de dados incompletos, inexatos ou desatualizados; <br></br>
                                <span style={{ marginBottom: 15 }}>¬ </span>IV - anonimização, bloqueio ou eliminação de dados desnecessários,
                                excessivos ou tratados em desconformidade com o disposto nesta Lei; <br></br>
                                <span style={{ marginBottom: 15 }}>¬ </span>V - portabilidade dos dados a outro fornecedor de serviço ou produto,
                                mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial
                                e industrial; <br></br>
                                <span style={{ marginBottom: 15 }}>¬ </span>VI - eliminação dos dados pessoais tratados com o consentimento do
                                titular, exceto nas hipóteses previstas no art.16 desta Lei; <br></br>
                                <span style={{ marginBottom: 15 }}>¬ </span>VII - informação das entidades públicas e privadas com as quais o
                                controlador realizou uso compartilhado dedados; <br></br>
                                <span style={{ marginBottom: 15 }}>¬ </span>IX - revogação do consentimento; <br></br>
                            </p>
                        </p>
                        <br></br>
                    </div>
                </Box>
            </div>

            <div>
                <Title style={{ marginTop: "10vh" }}>
                    <p>Conhecendo os tipos de dados pessoais que podem ser tratados </p>
                    <hr></hr>
                </Title>

                <Box style={{ marginTop: 20 }}>
                    <div className="d-flex justify-content-center">
                        <p style={{ width: "70%", textAlign: "justify" }}>
                            <br></br>
                            <p style={{ marginLeft: "15px" }}>
                                <span style={{ marginBottom: 15 }}>¬ </span>Dados pessoais: São todos os tipos de dados que torna a pessoa natural
                                identificada ou identificável como por exemplo CPF, NOME, TELEFONE, ENDEREÇO, etc. <br></br>
                                <br></br>
                                <span style={{ marginBottom: 15 }}>¬ </span>Dados pessoais sensíveis: São todos os tipos de dados que podem trazer
                                algum tipo de constrangimento ou preconceito direta ou indiretamente, necessitando de uma segurança maior na proteção
                                e tratamento do dado, são considerados pela lei os seguintes dados sensíveis: origem racial ou étnica, convicção
                                religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado
                                referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural; <br></br>
                                <br></br>
                            </p>
                        </p>
                        <br></br>
                    </div>
                </Box>
            </div>

            <div style={{ backgroundColor: "#e4f5fc" }} className="d-flex justify-content-center">
                <Box style={{ marginTop: 10 }}>
                    <div className="d-flex justify-content-center">
                        <p style={{ width: "70%", textAlign: "justify" }}>
                            <div className="d-flex justify-content-start">
                                <Title style={{ marginTop: "5vh" }}>
                                    <p style={{ paddingLeft: "0px" }}>FALE COM NOSSO DPO: </p>
                                </Title>
                            </div>
                            <strong>
                                Caso tenha alguma dúvida, colocamos a disposição o nosso DPO Galeno José pelo e-mail:
                                dpo@grupocereal.com.br e telefone (64) 3051-8554. Através deste contato pode-se fazer suas solicitações visando exercer seus direitos como
                                titular de dados e também esclarecer dúvidas quanto ao tratamento de dados pessoais.
                            </strong>
                        </p>
                        <br></br>
                    </div>
                </Box>
            </div>

            <div className="d-flex justify-content-center">
                <Box style={{ marginTop: 20, borderLeftWidth: "0px", borderBottomWidth: "0px" }}>
                    <div className="d-flex justify-content-center">
                        <p style={{ width: "100%", textAlign: "justify" }}>
                            <br></br>
                            <strong>
                                Essa é a primeira versão do nosso aviso de privacidade, em breve teremos novas versões de acordo com a implementação
                                na empresa.
                            </strong>
                        </p>
                        <br></br>
                    </div>
                </Box>
            </div>
        </Container>
    );
}
