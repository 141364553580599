import styled from "styled-components";
import { FaTimes } from "react-icons/fa";

export const DropdownContainer = styled.div`
    display: none;

    @media (max-width: 1260px) {
        position: fixed;
        z-index: 99999;
        width: 30vw;
        height: 100vh;
        background: #01388a;
        display: block;
        align-items: center;
        top: 0;
        right: 0;
        transition: 0.1s ease-in-out;
        opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
        top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
    }
`;

export const DivIcon = styled.div`
    margin-right: 5px;
    text-align: "right";
`;

export const CloseIcon = styled(FaTimes)`
    color: white;
    font-size: 20px;
    outline: none;
    display: absolute;
    text-align: right;
    transition: 0.2s ease-in-out;

    &:hover {
        color: black;
    }
`;
export const DropdownMenu = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 80px);
    text-align: center;
    margin-bottom: 4rem;

    @media screen and (max-width: 480px) {
        grid-template-rows: repeat(4, 60px);
    }

    li {
        margin: 30px 5px 10px 5px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        color: #fff;
        font-size: 0.8rem;
        text-decoration: none;
        list-style: none;
        color: #fff;
        cursor: pointer;
        transition: 0.2s ease-in-out;

        &:hover {
            color: #000d1a;
        }
    }
`;
