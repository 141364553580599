import React from "react";
import { Outlet } from "react-router";
import { Header } from "./components/Header";
import Dropdown from "./components/Dropdown";
import "./App.css";
import Footer from "components/Footer/Footer";
function App() {
    const [isOpen, setIsOpen] = React.useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (

        <div className="page-container" >
            <Header toggle={toggle} />
            <Dropdown isOpen={isOpen} toggle={toggle} />
            <div className="content-wrap" >
                <Outlet />
            </div>
            <Footer />
        </div>
    );
}

export default App;
