import { Container, Title, ContatoContainer, PrincipalContatoItem } from "./styles";
import { FaPhoneAlt, FaVoicemail } from "react-icons/fa";
import { AiFillSecurityScan } from "react-icons/ai";
import { Image } from "react-bootstrap";

export default function LgpdContato(...props) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                position: "relative",
            }}
            className='mt-5'
        >
            <Container>
                <div
                    style={{
                        position: "absolute",
                        top: "-110px",
                        left: "50px",
                    }}
                    className="d-flex align-items-center"
                >
                    <Image style={{ height: "150px" }} src="images/logos/gc-logo.png"></Image>
                </div>
                <Title>
                    <p style={{ textShadow: "1px 1px 2px black" }}>Contate-nos</p>
                    <p
                        style={{
                            fontSize: 25,
                            letterSpacing: 2,
                            textShadow: "1px 1px 2px black",
                        }}
                    >
                        ESTAMOS À DISPOSIÇÃO
                    </p>
                    <hr></hr>
                </Title>
                <br></br>
                <br></br>
                <ContatoContainer>
                    <PrincipalContatoItem>
                        <AiFillSecurityScan size={25} style={{ marginRight: 20 }}></AiFillSecurityScan>
                        Galeno José - DPO
                    </PrincipalContatoItem>
                    <PrincipalContatoItem>
                        <FaPhoneAlt style={{ marginRight: 20 }} />( 64 ) 3051 - 8554
                    </PrincipalContatoItem>
                    <PrincipalContatoItem>
                        <FaVoicemail style={{ marginRight: 20 }} />
                        dpo@grupocereal.com.br
                    </PrincipalContatoItem>
                </ContatoContainer>
                <br></br>
                <br></br>
            </Container>
        </div>
    );
}
