import React from "react";
import { Routes, Route } from "react-router-dom";
import App from "../App";
import Home from "../pages/Home";
import SGI from "../pages/CertificadosGeral/PoliticaSgi/SGI";
import NotFound from "pages/404";
import ApresentacaoInstitucional from "pages/RelacionamentoInvestidor/ApresentacaoInstitucional";
import BalancosAuditados from "pages/RelacionamentoInvestidor/BalancosAuditados";
import LGPD from "pages/LGPDGeral/LGPD";
import LgpdAvisoPrivacidadeHome from "pages/LGPDGeral/LgpdAvisoPrivacidadeHome";
import RelatorioDeTransparencia from "pages/RelacionamentoInvestidor/RelatorioDeTransparencia";
import RenovaBio from "pages/CertificadosGeral/Renovabio/Renovabio";
import Rating from "pages/RelacionamentoInvestidor/Rating";
import BPF from "pages/CertificadosGeral/BPF/BPF";
import GMP from "pages/CertificadosGeral/GMP/GMP";


export default function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<App />}>
                <Route index element={<Home />} />
                <Route path="/sgi" element={<SGI />} />
                <Route path="/renovabio" element={<RenovaBio />} />
                <Route path="/bpf" element={<BPF />} />
                <Route path="/gmp" element={<GMP />} />
                <Route path="/apresentacaoInstitucional" element={<ApresentacaoInstitucional />} />
                <Route path="/balancoAuditados" element={<BalancosAuditados />} />
                <Route path="/rating" element={<Rating />} />
                <Route path="/relatorioDeTransparencia" element={<RelatorioDeTransparencia />} />
                <Route path="/lgpd" element={<LGPD />} />
                <Route path="/lgpd-aviso-privacidade" element={<LgpdAvisoPrivacidadeHome />} />
                <Route path="*" exact element={<NotFound />} />
            </Route>
        </Routes>
    );
}
