import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: "Roboto";
    color: #fff;

    .imagemInicio {
        margin-top: 100px;
        width: 100%;
    }
`;

export const ImageSlide = styled.img`
    width: 1000px;
    height: 650px;
`;

export const Sections = styled.div`
    display: flex;
    height: 600px;
    margin-top: 100px;
`;

export const SectionItem = styled.div`
    width: 40%;
    overflow: hidden;
    margin-left: 20px;
    margin-top: -20px;
    box-shadow: 3px 3px 5px 4px #cccccc;
`;

export const SectionHeader = styled.div`
    width: 100%;
    height: 240px;
    position: relative;
    background-size: cover !important;
    background: url(${(props) => props.src}) center center no-repeat;

    b {
        bottom: 23px;
        color: #fff;
        font-family: "Roboto";
        font-size: 40px;
        font-weight: bold;
        line-height: 50px;
        padding-left: 15px;
        position: absolute;
        text-transform: uppercase;
        width: 100%;
    }
`;

export const ScrollContainer = styled.div`
    width: 100%;
    height: 500px;
    overflow-y: auto;
    position: relative;
    &::-webkit-scrollbar {
        width: 10px;
    }
`;

export const SectionBody = styled.div`
    font-family: "Roboto";
    font-size: 20px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.7);
    border-bottom: 1px solid #eee;
    border-left: 1px solid #eee;

    &::-webkit-scrollbar {
        width: 10px;
        border: 1px solid black;
    }

    &:last-child {
        border-right: 1px solid #eee;
    }

    p {
        padding: 30px;
    }
`;
