import styled from "styled-components";

export const Container = styled.div`
    background-color: #ecf0f1;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    justify-content: center;
`;

export const Welcome = styled.div`
    font-size: 45px;
    font-weight: 300;
    color: #7f8c8d;
    padding-bottom: 5px;
    text-align: center;
`;

export const UserName = styled.div`
    font-size: 50px;
    font-weight: 700;
    color: #2c3e50;
    text-align: center;
`;
