import React from "react";
import { Box, Container, Title } from "./styles";
import { Grid } from "@material-ui/core";

export default function GMPHome(...props) {
    return (
        <Container>
            <Title>
                <p>Certificado GMP</p>
                <hr />
            </Title>
            <br />
            <div className="d-flex justify-content-center">
                <Grid container spacing={2} style={{ marginTop: "5vh" }}>
                    <div>
                        <Box style={{ marginTop: 20, borderLeftWidth: "0px", borderBottomWidth: "0px" }} className="d-flex justify-content-center">
                            <p style={{ width: "90%", paddingLeft: "0px", textAlign: "justify", color: "#303484" }}>
                                A certificação GMP+ FSA (Feed Safety Assurance) é um padrão internacional que assegura a segurança na produção de
                                alimentos para animais, abrangendo todos os elos da cadeia de fornecimento. Fundamentada em normas como as Boas
                                Práticas de Fabricação (GMP) e a Análise de Perigos e Pontos Críticos de Controle (HACCP), a certificação tem como
                                objetivo prevenir contaminações, garantir a qualidade dos produtos e atender às exigências legais e do mercado global.
                                Atualmente, toda a cadeia de fornecimento de farelo de soja do Grupo Cereal, incluindo produção, comercialização,
                                transporte e afretamento ferroviário, é certificada conforme os padrões GMP+ FSA.
                            </p>
                        </Box>
                    </div>
                </Grid>
            </div>
            <br />
            <h2 className="heading-secondary">
                <a href="/certificado_gmp.pdf" className="link">
                    Certificado - pdf
                </a>
            </h2>
        </Container>
    );
}
