import React from "react";
import { Card, Container, SectionCard, Title, TitleCard } from "./styles";
import { RiDatabaseFill, RiGitRepositoryPrivateFill } from "react-icons/ri";
import { GiStarsStack } from "react-icons/gi";
import { Link } from "react-router-dom";
import { CustomButton } from "../LgpdAvisoPrivacidadeHome/styles";

export default function LgpdHome(...props) {
    return (
        <Container style={{marginTop: "109px"}}>
            <Title>
                <p>LGPD - Lei Geral de Proteção de Dados Pessoais</p>
                <hr></hr>
            </Title>

            <SectionCard style={{ margin: "15vh 0px 10vh 0px" }}>
                <Card>
                    <TitleCard>
                        {" "}
                        <RiGitRepositoryPrivateFill
                            style={{
                                marginRight: 10,
                                fontSize: 25,
                            }}
                        />{" "}
                        Aviso de Privacidade
                    </TitleCard>
                    <hr></hr>
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        <p style={{ fontSize: "14px" }}>Clique no botão para visualizar.</p>
                    </div>
                    <div className="d-flex justify-content-center">
                        <CustomButton
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            component={Link} // Utilize o componente Link do react-router-dom
                            to="/lgpd-aviso-privacidade" // Defina a rota para a qual deseja direcionar
                            variant="contained"
                            color="white"
                        >
                            Aviso de Privacidade
                        </CustomButton>
                    </div>
                </Card>
                <Card>
                    <TitleCard>
                        <RiDatabaseFill
                            style={{
                                marginRight: 10,
                                fontSize: 25,
                            }}
                        />{" "}
                        O que é tratamento de dados?
                    </TitleCard>
                    <hr></hr>
                    <div className="d-flex justify-content-center">
                        <p
                            style={{
                                textAlign: "justify",
                                textJustify: "inter-character",
                                fontSize: "14px",
                                width: "90%"
                            }}
                        >
                            É toda operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação,
                            utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação,
                            avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.
                        </p>
                    </div>
                </Card>
                <Card>
                    <TitleCard>
                        <GiStarsStack
                            style={{
                                marginRight: 10,
                                fontSize: 25,
                            }}
                        />{" "}
                        Quais os benefícios da LGPD para o titular do dado?
                    </TitleCard>
                    <hr></hr>
                    <div>
                        <p style={{ marginLeft: "50px", fontSize: "13px" }}>
                            <span style={{ marginBottom: 15 }}>¬ </span>Conscentimento e controle dos dados; <br></br>
                            <span style={{ marginBottom: 15 }}>¬ </span>Acesso e transparência no tratamento; <br></br>
                            <span style={{ marginBottom: 15 }}>¬ </span>
                            Retificação e atualização; <br></br>
                            <span style={{ marginBottom: 15 }}>¬ </span>Portabilidade dos dados; <br></br>
                            <span style={{ marginBottom: 15 }}>¬ </span>
                            Eliminação dos dados; <br></br>
                            <span style={{ marginBottom: 15 }}>¬ </span>Segurança e responsabilidade; <br></br>
                        </p>
                    </div>
                </Card>
            </SectionCard>
        </Container>
    );
}
