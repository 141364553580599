import React from "react";
import { FaExclamationTriangle } from "react-icons/fa";
import { Container, UserName, Welcome } from "./styles";
import "./../../App.scss";

export default function NotFound(...props) {
    return (
        <Container style={{ height: "100%" }}>
            <UserName>
                <FaExclamationTriangle size={100} />
            </UserName>
            <Welcome>Essa pagina não existe!</Welcome>
        </Container>
    );
}
