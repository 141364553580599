import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    font-family: "Roboto";
    color: #fff;
    padding-bottom: 20px;
    background: linear-gradient(90deg, #4d5a8f 0%, #3a488f 50%, #123380 100%);
    width: 100%;
    border-radius: 20px 20px 0px 0px;
    position: relative;

    @media (max-width: 1200px) {
        img{
            display: none;
        }
    }
`;

export const Title = styled.div`
    padding-top: 25px;
    text-align: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        font-size: 20px;
    }

    hr {
        width: 90px;
        height: 3px;
        background: #fff;
        right: 150px;
        border: none;
        margin: 0%;
    }
`;

export const ContatoContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;

    @media (max-width: 1260px) {
        display: flex;
        flex-direction: column;
    }
`;

export const ContatoItem = styled.div`
    padding: 10px;
    font-size: 16px;
    text-align: center;
    color: #fff;
`;

export const PrincipalContatoItem = styled.div`
    padding: 20px;
    font-size: 16px;
    text-align: center;
    color: #fff;

    @media (max-width: 1260px) {
        padding: 2px;
    }
`;
