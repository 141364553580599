import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../base.css";
import "../../../App.scss";
import { Container } from "./styles";
import LgpdHome from "../LgpdHome";
import LgpdContato from "pages/LGPDGeral/LgpdContato";

export default function LGPD() {
    return (
        <Container>
            <div style={{ paddingTop: "5vh", marginBottom: "5vh" }} id="lgpdhome">
                <LgpdHome />
            </div>
            <div style={{ paddingTop: "8vh" }} id="contato">
                <LgpdContato />
            </div>
        </Container>
    );
}
