import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../base.css";
import "../../../App.scss";
import { Container } from "./styles";
import ApresentacaoInstitucionalHome from "./BalancosAuditadosHome";


export default function ApresentacaoInstitucional() {
    return (
        <Container style={{marginTop: "109px"}}>
            <div id="lgpdhome">
                <ApresentacaoInstitucionalHome />
            </div>
        </Container>
    );
}