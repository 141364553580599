import { Container, Title, ContatoContainer, ContatoItem, PrincipalContatoItem } from "./styles";
import { FaLocationArrow, FaPhoneAlt, FaVoicemail } from "react-icons/fa";
import { Image } from "react-bootstrap";

export default function Contato() {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                position: "relative",
            }}
        >
            <Container>
                <div
                    id="contato"
                    style={{
                        position: "absolute",
                        top: "-110px",
                        left: "50px",
                    }}
                    className="d-flex align-items-center"
                >
                    <Image style={{ height: "150px" }} src="images/logos/gc-logo.png"></Image>
                </div>
                <Title>
                    <p style={{ textShadow: "1px 1px 2px black" }}>Contate-nos</p>
                    <p
                        style={{
                            fontSize: 25,
                            letterSpacing: 2,
                            textShadow: "1px 1px 2px black",
                        }}
                    >
                        ESTAMOS À DISPOSIÇÃO
                    </p>
                    <hr></hr>
                </Title>
                <br></br>
                <br></br>
                <ContatoContainer>
                    <PrincipalContatoItem>
                        <FaLocationArrow style={{ marginRight: 20 }}></FaLocationArrow>
                        BR-060, 381 - St. Industrial, Rio Verde - GO, 75.905-025 - Matriz
                    </PrincipalContatoItem>
                    <PrincipalContatoItem>
                        <FaPhoneAlt style={{ marginRight: 20 }} />( 64 ) 3611 - 8400
                    </PrincipalContatoItem>
                    <PrincipalContatoItem>
                        <FaVoicemail style={{ marginRight: 20 }} />
                        sac@grupocereal.com.br
                    </PrincipalContatoItem>
                </ContatoContainer>
                <br></br>
                <br></br>
                <ContatoContainer style={{ padding: "0px 0px 5vh 0px" }}>
                    <ContatoItem>Armazém Rio Verde I ( Matriz ) - ( 64 ) 3611 - 8488</ContatoItem>
                    <ContatoItem>Armazém Rio Verde II - ( 64 ) 3051 - 8588</ContatoItem>
                    <ContatoItem>Armazém Rio Verde III - ( 64 ) 3611 - 8491</ContatoItem>
                    <ContatoItem>Armazém Rio Preto - ( 64 ) 3051 - 8589</ContatoItem>
                    <ContatoItem>Armazém Paraúna - ( 64 ) 9 9995 - 1301</ContatoItem>
                    <ContatoItem>Armazém Montividiu – ( 64 ) 3629 - 1222</ContatoItem>
                    <ContatoItem>Armazém Edeia - ( 64 ) 9 9279 - 7019</ContatoItem>
                    <ContatoItem>Armazém Palmeiras de Goiás - ( 64 ) 3571 - 1175</ContatoItem>
                    <ContatoItem>Armazém Caiapônia - ( 64 ) 9 9933 - 1944</ContatoItem>
                </ContatoContainer>
            </Container>
        </div>
    );
}
