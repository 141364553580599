import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../../base.css";
import "../../../../App.scss";
import { Container } from "./styles";
import GMPHome from "../GMPHome";

export default function GMP() {
    return (
        <Container style={{ marginTop: "109px" }}>
            <div id="lgpdhome">
                <GMPHome />
            </div>
        </Container>
    );
}
