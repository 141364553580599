import React from "react";
import { Container, Title } from "./styles";

export default function RelatorioDeTransparenciaHome(props) {
    return (
        <Container>
            <Title>
                <p>RELATÓRIO DE TRANSPARÊNCIA</p>
                <hr />
            </Title>
            <br />
            <section class="" id="section-tours">
                <h2 class="heading-secondary">
                    <a href="/Rel_de_transparencia_e_igualdade_salarial_20224_2.pdf" className="link">
                        <div style={{ width: "60vw" }}>
                            Relatório de Transparência e Igualdade Salarial de Mulheres e Homens - 2º Semestre 2024 - pdf
                        </div>
                    </a>
                </h2><br/>
                <h2 class="heading-secondary">
                    <a href="/Relatorio_de_transparencia_e_igualdade_salarial.pdf" className="link">
                        <div style={{ width: "60vw" }}>
                            Relatório de Transparência e Igualdade Salarial de Mulheres e Homens - 1º Semestre 2024 - pdf
                        </div>
                    </a>
                </h2>
            </section>
        </Container>
    );
}
