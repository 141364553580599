import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../../base.css";
import "../../../../App.scss";
import { Container } from "./styles";
import RenovaBioHome from "../RenovaBioHome";

export default function RenovaBio() {
    return (
        <Container>
            <div style={{ paddingTop: "5vh", marginBottom: "5vh" }} id="sgihome">
                <RenovaBioHome />
            </div>
        </Container>
    );
}
