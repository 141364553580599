import Sobre from "../Sobre";
import Portfolio from "../Portfolio";
import Contato from "../Contato";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../base.css";
import { Container } from "./styles";
import "./../../App.scss";
import gif from "./../../assets/images-gif/gifBNDES.gif";
import "bootstrap/dist/js/bootstrap";
import { Image } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";

export default function Home(...props) {
    return (
        <Container
            style={{
                margin: "109px 0 0 0",
            }}
        >
            <div id="home">
                <Carousel data-bs-theme="dark" interval={10000}>
                    <Carousel.Item>
                        <Image src="images/logos/gc-banner-1.png" className="d-block w-100" alt="Grupo cereal"></Image>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image src="images/logos/gc-banner-2.png" className="d-block w-100" alt="Grupo cereal"></Image>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image src="images/logos/gc-banner-3.png" className="d-block w-100" alt="Grupo cereal"></Image>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image src="images/logos/gc-banner-4.png" className="d-block w-100" alt="Grupo cereal"></Image>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image src="images/logos/gc-banner-5.png" className="d-block w-100" alt="Grupo cereal"></Image>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image src="images/logos/gc-banner-6.png" className="d-block w-100" alt="Grupo cereal"></Image>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image src="images/logos/gc-banner-7.png" className="d-block w-100" alt="Grupo cereal"></Image>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image src="images/logos/gc-banner-8.png" className="d-block w-100" alt="Grupo cereal"></Image>
                    </Carousel.Item>
                    {/*                     <Carousel.Item>
                        <video autoPlay muted playsinline loop style={{ pointerEvents: "none" }} className="d-block w-100" src="" controls />
                    </Carousel.Item> */}
                </Carousel>
            </div>

            <div style={{ paddingTop: "5vh" }}>
                <Sobre />
            </div>
            <div style={{ paddingTop: "10vh" }}>
                <Portfolio />
            </div>

            <div>
                <a className="d-flex justify-content-center" href="https://www.bndes.gov.br">
                    <Image src={gif} className=" d-flex justify-content-center d-block w-50"></Image>
                </a>
            </div>
            <div style={{ paddingTop: "8vh" }} id="contato">
                <Contato />
            </div>
        </Container>
    );
}
