import React from "react";
import { Box, Container } from "./styles";
import { Grid } from "@material-ui/core";

import sgiImg from "./../../../../assets/sgi.png";

export default function SGIHome(...props) {
    return (
        <Container>
            <div>
                <img src={sgiImg} alt="um" className="imagemInicio" />
            </div>

            <Box style={{ marginTop: "10vh", borderLeftWidth: "0px", borderBottomWidth: "0px" }}>
                <div className="d-flex justify-content-center">
                    <h3
                        style={{
                            width: "90%",
                            textAlign: "center",
                            backgroundColor: "#303484",
                            borderRadius: "5px",
                            color: "white",
                            padding: "10px",
                            margin: "0 0 10px 0",
                        }}
                    >
                        POLÍTICA DE GESTÃO INTEGRADA QUALIDADE, MEIO AMBIENTE, SAÚDE E SEGURANÇA
                    </h3>
                </div>
            </Box>

            <div className="d-flex justify-content-center">
                <Grid container spacing={2} style={{ marginTop: "5vh" }}>
                    <div>
                        <Box style={{ marginTop: 20, borderLeftWidth: "0px", borderBottomWidth: "0px" }} className="d-flex justify-content-center">
                            <p style={{ width: "90%", paddingLeft: "0px", textAlign: "justify", color: "#303484" }}>
                                O Grupo Cereal empresa do ramo agroindustrial, que atende o mercado nacional e internacional, transformando cereais em
                                alimentos e energia, assume o compromisso com a sociedade, de buscar por meio de um Sistema de Gestão Integrado, a
                                qualidade do produto, desenvolvimento econômico, preservação do meio ambiente, da saúde e integridade física de seus
                                colaboradores, através dos seguintes princípios:
                            </p>
                        </Box>
                    </div>
                </Grid>
            </div>

            <div className="d-flex justify-content-center">
                <Grid container spacing={2} className="d-flex justify-content-start p-5">
                    <div>
                        <Box style={{ marginTop: 20, borderLeftWidth: "0px", borderBottomWidth: "0px" }} className="d-flex justify-content-center">
                            <p
                                style={{
                                    textAlign: "justify",
                                    color: "#303484",
                                }}
                            >
                                <span>• </span> Consolidar a segurança nos processos, reduzindo riscos à saúde e segurança dos colaboradores;
                            </p>
                        </Box>
                    </div>
                    <div>
                        <Box style={{ marginTop: 20, borderLeftWidth: "0px", borderBottomWidth: "0px" }} className="d-flex justify-content-center">
                            <p
                                style={{
                                    textAlign: "justify",

                                    color: "#303484",
                                }}
                            >
                                <span>• </span> Impulsionar o desempenho organizacional com menor impacto ambiental, uma iniciativa de se fazer mais e
                                melhor com menos.
                            </p>
                        </Box>
                    </div>
                    <div>
                        <Box style={{ marginTop: 20, borderLeftWidth: "0px", borderBottomWidth: "0px" }} className="d-flex justify-content-center">
                            <p
                                style={{
                                    textAlign: "justify",

                                    color: "#303484",
                                }}
                            >
                                <span>• </span> Assegurar a fabricação e transporte dos produtos, promovendo confiabilidade e atendendo as
                                especificações, prestando um serviço de qualidade para as partes interessadas.
                            </p>
                        </Box>
                    </div>
                    <div>
                        <Box style={{ marginTop: 20, borderLeftWidth: "0px", borderBottomWidth: "0px" }} className="d-flex justify-content-center">
                            <p
                                style={{
                                    textAlign: "justify",

                                    color: "#303484",
                                }}
                            >
                                <span>• </span> Realizar suas atividades de forma sustentável, buscando o equilíbrio entre o meio ambiente, a
                                comunidade e a economia da organização.
                            </p>
                        </Box>
                    </div>

                    <div>
                        <Box style={{ marginTop: 20, borderLeftWidth: "0px", borderBottomWidth: "0px" }} className="d-flex justify-content-center">
                            <p
                                style={{
                                    textAlign: "justify",

                                    color: "#303484",
                                }}
                            >
                                <span>• </span> Garantir a conformidade das condutas e processos com os padrões estabelecidos, identificar e propor
                                soluções para a melhoria contínua.
                            </p>
                        </Box>
                    </div>

                    <div>
                        <Box style={{ marginTop: 20, borderLeftWidth: "0px", borderBottomWidth: "0px" }} className="d-flex justify-content-center">
                            <p
                                style={{
                                    textAlign: "justify",

                                    color: "#303484",
                                }}
                            >
                                <span>• </span> Promover conhecimento, atitudes e valores necessários para formar a opinião de seus colaboradores e
                                parceiros sobre a preservação, redução do impacto ambiental e a segurança alimentar do produto.
                            </p>
                        </Box>
                    </div>

                    <div>
                        <Box style={{ marginTop: 20, borderLeftWidth: "0px", borderBottomWidth: "0px" }} className="d-flex justify-content-center">
                            <p
                                style={{
                                    textAlign: "justify",

                                    color: "#303484",
                                }}
                            >
                                <span>• </span> Buscar o atendimento dos requisitos legais e aplicáveis ao SCI atuando de forma preventiva nos
                                processos.
                            </p>
                        </Box>
                    </div>

                    <div>
                        <Box style={{ marginTop: 20, borderLeftWidth: "0px", borderBottomWidth: "0px" }} className="d-flex justify-content-center">
                            <p
                                style={{
                                    textAlign: "justify",

                                    color: "#303484",
                                }}
                            >
                                <span>• </span> Avançar tecnologicamente, renovando os costumes comportamentos, buscando novas oportunidades de
                                negócios, primando pela qualidade, respeito ao meio ambiente e zelando pela saúde e segurança do colaborador.
                            </p>
                        </Box>
                    </div>
                </Grid>
            </div>
        </Container>
    );
}
