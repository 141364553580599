import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../base.css";
import "../../../App.scss";
import { Container } from "./styles";
import RelatorioDeTransparenciaHome from "./RelatorioDeTransparenciaHome";


export default function RelatorioDeTransparencia() {
    return (
        <Container style={{marginTop: "109px"}}>
            <div id="RtransparenciaHome">
                <RelatorioDeTransparenciaHome />
            </div>
        </Container>
    );
}