import styled from "styled-components/macro";
import { RiBarChartHorizontalLine } from "react-icons/ri";



export const MenuBars = styled(RiBarChartHorizontalLine)`
    display: none;

    @media screen and (max-width: 1260px) {
        margin: 3px 15px;
        display: block;
        height: 50px;
        color: black;
        width: 30px;
        position: absolute;
        right: 0;
        transform: translate(-50%, 45%);
        cursor: pointer;
    }
`;
