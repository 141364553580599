import React, { useState } from "react";

import {
    Container,
    Title,
    ContentSobre,
    PortifolioHeader,
    PortifolioHeaderItem,
    LinhaDoTempoBody,
    LinhaDoTempoItem,
    TextLinhaDoTempo,
} from "./styles";

export default function Portfolio(...props) {
    const [portifolioAtivo, setPortifolioAtivo] = useState("CEREALRENOVA");

    return (
        <Container id="portifolio">
            <Title>
                <p>Divisões de Serviços</p>
                <p style={{ fontSize: 40, letterSpacing: 5 }}>NOSSO PORTFÓLIO</p>
                <hr></hr>
            </Title>

            <ContentSobre style={{ paddingTop: "5vh" }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                    }}
                >
                    <PortifolioHeader>
                        <PortifolioHeaderItem active={"CEREALBARTER" === portifolioAtivo} onClick={() => setPortifolioAtivo("CEREALBARTER")}>
                            BARTER
                        </PortifolioHeaderItem>
                        <PortifolioHeaderItem active={"CEREALORIGINACAO" === portifolioAtivo} onClick={() => setPortifolioAtivo("CEREALORIGINACAO")}>
                            ORIGINAÇÃO
                        </PortifolioHeaderItem>
                        <PortifolioHeaderItem
                            active={"CEREALARMAZENAGENS" === portifolioAtivo}
                            onClick={() => setPortifolioAtivo("CEREALARMAZENAGENS")}
                        >
                            ARMAZENAGENS
                        </PortifolioHeaderItem>
                        <PortifolioHeaderItem
                            active={"CEREALCOMMODITIES" === portifolioAtivo}
                            onClick={() => setPortifolioAtivo("CEREALCOMMODITIES")}
                        >
                            COMMODITIES
                        </PortifolioHeaderItem>
                        <PortifolioHeaderItem
                            active={"CEREALNUTRICAOANIMAL" === portifolioAtivo}
                            onClick={() => setPortifolioAtivo("CEREALNUTRICAOANIMAL")}
                        >
                            NUTRIÇÃO ANIMAL
                        </PortifolioHeaderItem>
                        <PortifolioHeaderItem active={"BIODIESEL" === portifolioAtivo} onClick={() => setPortifolioAtivo("BIODIESEL")}>
                            BIODIESEL
                        </PortifolioHeaderItem>
                        <PortifolioHeaderItem active={"CEREALBIOFERTIL" === portifolioAtivo} onClick={() => setPortifolioAtivo("CEREALBIOFERTIL")}>
                            CEREAL BIOFERTIL
                        </PortifolioHeaderItem>

                        <PortifolioHeaderItem active={"CEREALTRANSPORTE" === portifolioAtivo} onClick={() => setPortifolioAtivo("CEREALTRANSPORTE")}>
                            CEREAL TRANSPORTE
                        </PortifolioHeaderItem>
                        <PortifolioHeaderItem
                            active={"CONDOMINIOBARAUNA" === portifolioAtivo}
                            onClick={() => setPortifolioAtivo("CONDOMINIOBARAUNA")}
                        >
                            CONDOMINIO BARAUNA
                        </PortifolioHeaderItem>
                    </PortifolioHeader>
                    <LinhaDoTempoBody>
                        <LinhaDoTempoItem active={"BIODIESEL" === portifolioAtivo}>
                            <div>
                                <Title style={{ fontSize: 28, marginBottom: 10 }}>
                                    Biodiesel
                                    <hr></hr>
                                </Title>

                                <TextLinhaDoTempo>
                                    Com o objetivo de agregar valor pensado no meio ambiente, uma prática presente no DNA do Grupo Cereal, estamos
                                    verticalizando, ainda mais, nosso negócio, transformando em Biodiesel todo o óleo degomado produzido em nossa
                                    indústria. Entre as vantagens que este novo negócio oferece, a negociação da compra de soja para a produção do
                                    Biodiesel, entre o Grupo Cereal e produtores da região, está o fomento direto da agricultura familiar. E também
                                    passamos a integrar um seleto hall das empresas que produzem energia limpa, conquistando assim, a chancela do Selo
                                    Combustível Social.
                                </TextLinhaDoTempo>
                            </div>
                        </LinhaDoTempoItem>
                        <LinhaDoTempoItem active={"CEREALBIOFERTIL" === portifolioAtivo}>
                            <div>
                                <Title style={{ fontSize: 28, marginBottom: 10 }}>
                                    Adubo Orgânico
                                    <hr></hr>
                                </Title>

                                <TextLinhaDoTempo>
                                    O reaproveitamento de resíduos orgânicos é uma realidade no Grupo Cereal. A empresa desenvolve o chamado ciclo
                                    completo, que transforma resíduos orgânicos industriais e rurais em adubo nutritivo para lavouras. Os resíduos
                                    orgânicos reutilizados são provenientes do armazenamento de grãos, da fabricação de farelo de soja, das cinzas da
                                    caldeira e das atividades de suinocultura e bovinocultura da Fazenda Rio Verdinho, de propriedade do Grupo. São
                                    produzidas e comercializadas 25 mil toneladas de adubo orgânico por ano.
                                </TextLinhaDoTempo>
                            </div>
                        </LinhaDoTempoItem>

                        <LinhaDoTempoItem active={"CEREALINOVA" === portifolioAtivo}>
                            <div>
                                <Title style={{ fontSize: 28, marginBottom: 10 }}>
                                    Inovação
                                    <hr></hr>
                                </Title>

                                <TextLinhaDoTempo>
                                    Inovação e tecnologia estão na pauta do dia das grandes empresas. Com o Grupo Cereal não é diferente. Na busca
                                    constante pela melhoria dos seus processos de gestão e produção, a empresa está lançando o Cereal Inova, uma
                                    frente multidisciplinar cujo objetivo é receber ideias e dar voz ativa aos seus colaboradores para cooperação com
                                    o Grupo. O Cereal Inova espera agregar valor com o intuito de incentivar os colaboradores de todas as áreas a
                                    contribuírem com seus conhecimentos para levantar discussões e propostas relacionadas à agilidade de processos,
                                    melhoria de atendimento, transformação digital e acompanhamento de tendências. Tudo isso, visando as inovações da
                                    Indústria e Agricultura 4.0. Cereal Inova não se trata apenas de automatizar processos, mas sim, de criar uma
                                    cultura de inovação dentro da empresa, incentivando os colaboradores de todas as áreas a manifestarem soluções
                                    criativas para desenvolver talentos dentro da empresa.
                                </TextLinhaDoTempo>
                            </div>
                        </LinhaDoTempoItem>

                        <LinhaDoTempoItem active={"CEREALTRANSPORTE" === portifolioAtivo}>
                            <div>
                                <Title style={{ fontSize: 28, marginBottom: 10 }}>
                                    Transportadora
                                    <hr></hr>
                                </Title>

                                <TextLinhaDoTempo>
                                    Pensando em viabilizar o escoamento de grãos entre as principais rotas do país e, atento às adversidades do
                                    transporte de cargas no Brasil, o Grupo Cereal investiu na compra de caminhões para otimizar, em tempo e custo
                                    financeiro, a logística de compra e venda de seus produtos e matérias-primas, deixando assim de depender de
                                    transportadoras terceirizadas.
                                </TextLinhaDoTempo>
                            </div>
                        </LinhaDoTempoItem>

                        <LinhaDoTempoItem active={"CEREALNUTRICAOANIMAL" === portifolioAtivo}>
                            <div>
                                <Title style={{ fontSize: 28, marginBottom: 10 }}>
                                    Nutrição Animal
                                    <hr></hr>
                                </Title>

                                <TextLinhaDoTempo>
                                    O Grupo Cereal conta com uma fábrica bem equipada e em atividade desde 2001. Além disso, possui em seu corpo de
                                    profissionais, uma equipe técnica altamente qualificada. Essa estrutura corporativa é responsável pela produção de
                                    rações para aves, bovinos de corte e de leite, suínos, equinos, sal mineral e proteínados, além de rações sob
                                    medida para cada cliente. A empresa alia tecnologia avançada às necessidades de cada negócio. A Fábrica de Ração
                                    do Grupo Cereal possui o selo BPF (Boas Práticas de Fabricação - IN 04).
                                </TextLinhaDoTempo>
                            </div>
                        </LinhaDoTempoItem>

                        <LinhaDoTempoItem active={"CEREALCOMMODITIES" === portifolioAtivo}>
                            <div>
                                <Title style={{ fontSize: 28, marginBottom: 10 }}>
                                    Commodities
                                    <hr></hr>
                                </Title>

                                <TextLinhaDoTempo>
                                    O Grupo Cereal conta com indústria de esmagamento de soja que tem, atualmente, capacidade para 2.000 ton./dia de
                                    grãos. A empresa consegue transformar 80% desse total em farelo de soja. Os outros 20% são destinados à produção
                                    de óleo degomado.
                                </TextLinhaDoTempo>
                                <Title style={{ fontSize: 28, marginBottom: 10 }}>
                                    Farelo de Soja
                                    <hr></hr>
                                </Title>

                                <TextLinhaDoTempo>
                                    O farelo de soja é empregado na composição de rações usadas na pecuária e na criação doméstica de animais. Ele
                                    contém alto valor proteico, além de atuar como um veículo eficaz na administração de antibióticos e vitaminas em
                                    animais.
                                </TextLinhaDoTempo>
                                <Title style={{ fontSize: 28, marginBottom: 10 }}>
                                    Óleo Degomado
                                    <hr></hr>
                                </Title>

                                <TextLinhaDoTempo>
                                    Uso industrial (refinarias de biodiesel, refinarias de óleo para consumo humano, indústria de cosméticos, entre
                                    outros). Hoje toda nossa produção é revertida em Biodiesel em nossa Usina própria.
                                </TextLinhaDoTempo>
                                <Title style={{ fontSize: 28, marginBottom: 10 }}>
                                    Soja Desativada
                                    <hr></hr>
                                </Title>

                                <TextLinhaDoTempo style={{ paddingBottom: 50 }}>
                                    A soja desativada, também chamada de soja integral, é um produto nobre, com alto valor energético, oferecido a
                                    criadores de aves, bovinos e suínos. Há cerca de 20 anos, este é um processo que exige grande know-how. O Grupo
                                    Cereal comercializa a soja desativada, produto que se tornou um diferencial no ramo da nutrição animal.
                                </TextLinhaDoTempo>
                            </div>
                        </LinhaDoTempoItem>

                        <LinhaDoTempoItem active={"CEREALORIGINACAO" === portifolioAtivo}>
                            <div>
                                <Title style={{ fontSize: 28, marginBottom: 10 }}>
                                    Originação de Grãos
                                    <hr></hr>
                                </Title>

                                <TextLinhaDoTempo>
                                    Devido à alta produtividade e a localidade da região, temos condições de comprar e vender grãos de soja, milho e
                                    sorgo, aproveitando a logística do Grupo O Grupo Cereal possui unidades estrategicamente posicionadas em todo o
                                    Sudoeste Goiano. Os vários terminais de transbordo instalados ao longo da região oferecem condição extremamente
                                    favorável ao produtor, que obtém mais facilidade na entrega e na comercialização do seu produto.
                                    <br></br>
                                    <br></br>
                                    <Title
                                        style={{
                                            fontSize: 28,
                                            marginBottom: 10,
                                        }}
                                    >
                                        Armazenamento de grãos com qualidade e segurança
                                        <hr></hr>
                                    </Title>
                                    <br></br>
                                    <br></br> A excelente localização do complexo armazenador do Grupo Cereal propicia ao produtor rural
                                     um eficiente escoamento de produção de grãos e assegura o armazenamento de sua safra. O Grupo
                                      Cereal possui unidades armazenadoras situadas em Rio Verde, Acreúna, Santo Antônio da Barra, 
                                      Palmeiras de Goiás, Edeia, Montividiu, Caiapônia, Ponte de Pedra, Jataí e região do Rio Preto. 
                                      Os grãos passam por processo de classificação, limpeza, secagem e, em seguida, são armazenados. 
                                      Consulte um de nossos encarregados nas Unidades, para que você possa armazenar sua 
                                      produção com toda agilidade e confiança.        
                                </TextLinhaDoTempo>
                            </div>
                        </LinhaDoTempoItem>

                        <LinhaDoTempoItem active={"CEREALBARTER" === portifolioAtivo}>
                            <div>
                                <Title style={{ fontSize: 28, marginBottom: 10 }}>
                                    Insumos
                                    <hr></hr>
                                </Title>

                                <TextLinhaDoTempo>
                                    A ‘Cereal Insumos’ foi criada em 2010 para atender de maneira personalizada o produtor e parceiro do Grupo Cereal.
                                    O departamento utiliza a modalidade de comércio chamada “barter”. O barter é o comércio de bens e serviços sem a
                                    utilização de dinheiro. O produtor rural recebe da empresa defensivos agrícolas e insumos necessários para sua
                                    lavoura e, como forma de pagamento, oferece à companhia parte da produção agrícola. Com o barter é possível
                                    segurar o valor da produção, protegendo o preço do grão de uma variação futura no mercado. Esse processo é
                                    conhecido como hedge. O hedge fixa, antecipadamente, o preço da mercadoria negociada com o Grupo Cereal,
                                    oferecendo garantias de que o preço da dívida não sofrerá alteração. A negociação em barter cria a moeda do
                                    produtor, resultando nos melhores índices de produtividade para sua lavoura. <br></br>Além de contar com um seguro
                                    para sua lavoura, serviço oferecido pelo Grupo Cereal para completar o pacote de serviços e produtos de alta
                                    qualidade.
                                </TextLinhaDoTempo>
                            </div>
                        </LinhaDoTempoItem>

                        <LinhaDoTempoItem active={"CEREALARMAZENAGENS" === portifolioAtivo}>
                            <div>
                                <Title style={{ fontSize: 28, marginBottom: 10 }}>
                                    Armazenamento de grãos com qualidade e segurança
                                    <hr></hr>
                                </Title>

                                <TextLinhaDoTempo>
                                      A excelente localização do complexo armazenador do Grupo Cereal propicia ao produtor rural
                                      um eficiente escoamento de produção de grãos e assegura o armazenamento de sua safra. O Grupo
                                      Cereal possui unidades armazenadoras situadas em Rio Verde, Acreúna, Santo Antônio da Barra, 
                                      Palmeiras de Goiás, Edeia, Montividiu, Caiapônia, Ponte de Pedra, Jataí e região do Rio Preto. 
                                      Os grãos passam por processo de classificação, limpeza, secagem e, em seguida, são armazenados. 
                                      Consulte um de nossos encarregados nas Unidades, para que você possa armazenar sua 
                                      produção com toda agilidade e confiança.    
                                </TextLinhaDoTempo>
                            </div>
                        </LinhaDoTempoItem>

                        <LinhaDoTempoItem active={"CONDOMINIOBARAUNA" === portifolioAtivo}>
                            <div>
                                <Title style={{ fontSize: 28, marginBottom: 10 }}>
                                    Pecuária de Leite
                                    <hr></hr>
                                </Title>

                                <TextLinhaDoTempo>
                                    A pecuária de leite é outro ramo bem-sucedido do Grupo Cereal. Nesse segmento, a empresa possui um grande rebanho
                                    de vacas da raça holandesa, responsável pela produção de cerca de oito mil litros de leite por dia. Toda a criação
                                    do Grupo Cereal é Puro de Origem (P.O.) e registrado na Associação Brasileira dos Criadores de Bovinos da Raça
                                    Holandesa, o que garante ainda mais a qualidade do rebanho.
                                </TextLinhaDoTempo>
                                <TextLinhaDoTempo>
                                    Para aprimorar a qualidade do trabalho realizado no segmento de pecuária leiteira, o Grupo Cereal implantou, desde
                                    junho de 2012, o free stall. A tecnologia é importada do Canadá e consiste em um sistema inteligente de
                                    confinamento que possibilita aos animais um ambiente confortável, com área de descanso para as vacas em lactação e
                                    um espaço com reaproveitamento orgânico de dejetos que garante a sanidade do ambiente.
                                </TextLinhaDoTempo>
                                <TextLinhaDoTempo>
                                    A expectativa do Grupo Cereal é chegar a uma produção de 12 mil litros de leite por dia, a partir de investimentos
                                    na melhoria genética do rebanho. Além vender sêmen e embriões melhorados, promovendo, em um futuro breve, leilões
                                    para a venda de animais e elevação do valor agregado dos produtos da companhia.
                                </TextLinhaDoTempo>
                                <Title style={{ fontSize: 28, marginBottom: 10 }}>
                                    Suinocultura
                                    <hr></hr>
                                </Title>

                                <TextLinhaDoTempo>
                                    Atuando há mais de 25 anos no segmento da suinocultura, a Granja Santa Rita, de propriedade do Grupo, abriga 7,2
                                    mil cabeças de suínos, sendo 1,8 mil delas, matrizes que produzem, por mês, aproximadamente 3 mil leitões. Toda a
                                    produção é executada a partir do sistema de produção de leitão (SPL). E ainda contamos com a implantação do
                                    sistema de terminação na propriedade, uma forma de fechar o ciclo de produção de suínos, que são vendidos à
                                    abatedouros do Estado de Goiás.
                                </TextLinhaDoTempo>
                                <Title style={{ fontSize: 28, marginBottom: 10 }}>
                                    Agricultura
                                    <hr></hr>
                                </Title>

                                <TextLinhaDoTempo style={{ paddingBottom: 50 }}>
                                    O Grupo Cereal atua também no cultivo de soja, milho e sorgo.
                                </TextLinhaDoTempo>
                            </div>
                        </LinhaDoTempoItem>
                    </LinhaDoTempoBody>
                </div>
            </ContentSobre>
        </Container>
    );
}
