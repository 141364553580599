import React from "react";
import ReactDOM from "react-dom";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "./store";
import "./index.scss";
import AppRoutes from "Routes";

ReactDOM.render(
    <React.StrictMode>
        <Provider store={configureStore().store}>
            <PersistGate loading={null} persistor={configureStore().persistor}>
                <BrowserRouter>
                    <AppRoutes />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
