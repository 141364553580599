import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    font-family: "Roboto";
    color: #fff;
    align-items: center;
    width: 100%;
    justify-content: center;

    iframe {
        background-position: center;
        background-size: 100% 100%;
        background-repeat: none;

        text-align: center;
        height: 75vh;
        width: 55vw;

        @media (max-width: 1260px) {
            height: 30vh;
        }
    }
`;

export const Title = styled.div`
    text-align: center;
    color: #1f2183;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;

    p {
        font-size: 20px;
    }

    hr {
        width: 90px;
        height: 3px;
        background: #ff8456;
        right: 150px;
        border: none;
        margin: 0%;
    }
`;

export const Box = styled.div`
    font-family: "Roboto";
    font-size: 18px;
    font-weight: 400;
    color: #808080;
    border-bottom: 1px solid #eee;
    border-left: 1px solid #eee;
`;

export const SectionCard = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 200px;
    margin-top: 100px;

    @media (max-width: 1260px) {
        height: 700px;
        flex-direction: column;
    }
`;

export const Card = styled.div`
    background-color: #3c528f;
    border-radius: 25px 0px 25px 0px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    margin: 0px 20px 0px 20px;
    transition: 0.3s all;
    width: 50%;
    height: 45vh;

    @media (max-width: 1260px) {
        margin: 20px 0px 0px 0px;
        height: 50vh;
    }

    &:hover {
        transition: 0.3s all;
        box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.2), 0 16px 30px 0 rgba(0, 0, 0, 0.2);

        hr {
            transition: 0.3s;
            margin: 0 120px;
        }
    }

    p {
        padding: 20px;
    }

    hr {
        margin: 0 80px;
        transition: 0.3s margin;
    }
`;

export const TitleCard = styled.p`
    font-size: 18px;
    color: #fff;
    padding: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
`;
