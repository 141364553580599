import { useLocation } from "react-router";
import { Image, NavItem, Navbar, Dropdown } from "react-bootstrap";
import { MenuBars } from "./styles";
import "./styles.css";
import { Link } from "react-router-dom";

import { useState } from "react";
import LgpdModal from "components/LgpdModal";

export const Header = ({ toggle }) => {
    const location = useLocation();

    const [showModal, setShowModal] = useState(false);

    const linkForms =
        "https://forms.gle/Kwitk7Kx72Xmx9of6";
    
        const handleModalLgpdonContinuar = (checkedAceite) => {
            if (checkedAceite) {
                window.location.href = linkForms;
                setShowModal(false);
            }
        };
    
    
    const handleLogoClick = () => {
        if (
            location.pathname === "/lgpd" ||
            location.pathname === "/lgpd-aviso-privacidade" ||
            location.pathname === "/sgi" ||
            location.pathname === "/renovabio" ||
            location.pathname === "/bpf" ||
            location.pathname === "/gmp" ||
            location.pathname === "/balancoAuditados" ||
            location.pathname === "/apresentacaoInstitucional" ||
            location.pathname === "/relatorioDeTransparencia" ||
            location.pathname === "/rating"
        ) {
            window.location.href = "/#home";
        }
    };

    const showAllButtons =
        location.pathname !== "/lgpd" &&
        location.pathname !== "/lgpd-aviso-privacidade" &&
        location.pathname !== "/sgi" &&
        location.pathname !== "/renovabio" &&
        location.pathname !== "/bpf" &&
        location.pathname !== "/gmp" &&
        location.pathname !== "/balancoAuditados" &&
        location.pathname !== "/apresentacaoInstitucional" &&
        location.pathname !== "/relatorioDeTransparencia" &&
        location.pathname !== "/rating";
    const showLGPDButton = location.pathname === "/lgpd-aviso-privacidade";

    return (
        <Navbar fixed="top" className="light" >
            <LgpdModal showModal={showModal} onHideModal={()=>setShowModal(false)} onContinuarModal={handleModalLgpdonContinuar}/>
            <MenuBars onClick={toggle} />
            <div className="container-fluid">
                <a href="#home" className="navbar-brand" onClick={handleLogoClick}>
                    <Image className="image_logo" src="images/logos/gc-logo-campanha-1.png"></Image>
                </a>
                <Navbar.Collapse className="justify-content-end" style={{display: "none"}}>
                    {showAllButtons ? (
                        <>
                            <NavItem>
                                <text  className="nav-button-style-site-trabalhe-conosco" onClick={() => setShowModal(true)}>
                                    TRABALHE CONOSCO
                                </text >
                            </NavItem>
                            <NavItem>
                                <a href="#home" className="nav-button-style-site">
                                    INÍCIO
                                </a>
                            </NavItem>
                            <NavItem>
                                <a href="#sobre" className="nav-button-style-site">
                                    SOBRE
                                </a>
                            </NavItem>
                            <NavItem>
                                <a href="#portifolio" className="nav-button-style-site">
                                    PORTFÓLIO
                                </a>
                            </NavItem>
                            <NavItem bsPrefix="">
                                <a href="#contato" className="nav-button-style-site">
                                    CONTATO
                                </a>
                            </NavItem>
                            <NavItem>
                                <Link to="/lgpd">
                                    <text className="nav-button-style-site">LGPD</text>
                                </Link>
                            </NavItem>
                            <Dropdown>
                                <text
                                    className="dropdown-toggle nav-button-style-site"
                                    href="#"
                                    id="navbarDropdown"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Certificados
                                </text>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <Link to="/sgi" activeClassName="nav-item-active">
                                        <li>
                                            <text className="nav-button-style-site" href="#">
                                                Política SGI
                                            </text>
                                        </li>
                                    </Link>
                                    <Link to="/renovabio" activeClassName="nav-item-active">
                                        <li>
                                            <text className="nav-button-style-site" href="#">
                                                Renovabio
                                            </text>
                                        </li>
                                    </Link>
                                    <Link to="/gmp" activeClassName="nav-item-active">
                                        <li>
                                            <text className="nav-button-style-site" href="#">
                                                GMP
                                            </text>
                                        </li>
                                    </Link>
                                    <Link to="/bpf" activeClassName="nav-item-active">
                                        <li>
                                            <text className="nav-button-style-site" href="#">
                                                BPF
                                            </text>
                                        </li>
                                    </Link>
                                </ul>
                            </Dropdown>
                            <Dropdown>
                                <text
                                    className="dropdown-toggle nav-button-style-site"
                                    href="#"
                                    id="Relacionamento-dropDown"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Relacionamento com o Investidor
                                </text>
                                <ul className="dropdown-menu" aria-labelledby="Relacionamento-dropDown">
                                    <Link to="/apresentacaoInstitucional" activeClassName="nav-item-active">
                                        <li>
                                            <text className="nav-button-style-site" href="#">
                                                Apresentação Institucional
                                            </text>
                                        </li>
                                    </Link>
                                    <Link to="/balancoAuditados" activeClassName="nav-item-active">
                                        <li>
                                            <text className="nav-button-style-site" href="#">
                                                Balanços Auditados
                                            </text>
                                        </li>
                                    </Link>
                                    <Link to="/relatorioDeTransparencia" activeClassName="nav-item-active">
                                        <li>
                                            <text className="nav-button-style-site" href="#">
                                                Relatório de Transparência
                                            </text>
                                        </li>
                                    </Link>
                                    <Link to="/rating" activeClassName="nav-item-active">
                                        <li>
                                            <text className="nav-button-style-site" href="#">
                                                Rating
                                            </text>
                                        </li>
                                    </Link>
                                </ul>
                            </Dropdown>
                        </>
                    ) : (
                        showLGPD(showLGPDButton)
                    )}
                </Navbar.Collapse>
            </div>
        </Navbar>
    );
}; 

function showLGPD(showLGPDButton) {
    if (showLGPDButton) {
        return (
            <>
                <NavItem>
                    <Link to="/">
                        <text className="nav-button-style-site">INÍCIO</text>
                    </Link>
                </NavItem>
                <NavItem>
                    <Link to="/lgpd">
                        <text className="nav-button-style-site">LGPD</text>
                    </Link>
                </NavItem>
            </>
        );
    } else {
        return (
                <NavItem>
                    <Link to="/">
                        <text className="nav-button-style-site">INÍCIO</text>
                    </Link>
                </NavItem>

        );
    }
}
