import React, { useState } from "react";

import { Modal, Button, Form,  } from "react-bootstrap";
import { Title } from "pages/Sobre/styles";


function LgpdModal({ showModal, onHideModal, onContinuarModal }) {
    const [checkedAceite, setCheckedAceite] = useState(false);

    return (
        <Modal show={showModal} onHide={onHideModal}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <Title>Informativo Lei n.13.709/18 (LGPD)</Title>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{ textAlign: "justify" }}>
                    O Grupo Cereal, em decorrência da lei n.13.709/18 (Lei Geral de Proteção de Dados), se reserva no direito de utilizar os dados
                    pessoais registrados nesse formulário para interesse do Controlador, usando a base legal de legítimo interesse, resguardado pelo
                    artigo 10 § 1 da mesma lei.
                    <p>
                        Caso você tenha dúvidas relacionadas a esta ou outra forma de tratamento de dados pessoais realizada pelo Grupo Cereal, acesse
                        nossa página de proteção de dados pessoais, nosso aviso de privacidade ou entre em contato com nosso DPO.{" "}
                        <a
                            href="http://www.
grupocereal.com.br/lgpd"
                        >
                            http://www.grupocereal. com.br/lgpd.
                        </a>{" "}
                    </p>
                </div>
                <div style={{ display: "inline-block" }}>
                    <Form.Check
                        aria-label="option 1"
                        required={true}
                        label={
                            <div style={{ fontSize: "18px" }}>
                                Li e concordo com os <a href="http://www.grupocereal.com.br/lgpd-aviso-privacidade">termos de uso e política de privacidade.</a>
                            </div>
                        }
                        onChange={() => {
                            setCheckedAceite(!checkedAceite);
                            console.log(checkedAceite);
                        }}
                        checked={checkedAceite}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={!checkedAceite} variant="primary" onClick={() => onContinuarModal(checkedAceite)}>
                    Continuar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default LgpdModal;
